h1 {
  text-align: center;
  letter-spacing: 0;
  color: #003a40;
  opacity: .8;
}

h3 {
  font-size: 13px;
}

.home-link {
  width: 100%;
  text-align: center;
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-around;
  display: flex;
}

.home-link button {
  color: #fff;
  width: 60%;
  font-weight: 700;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-size: 13px !important;
}

body {
  height: 100vh;
}

.pac-container {
  z-index: 1100 !important;
}

.pac-item {
  text-align: center;
}

.cu-pos-btn {
  color: #fff;
  background-color: gray;
}

/*# sourceMappingURL=index.3a226588.css.map */
