h1 {
  text-align: center;
  /* font: sans-serif 600 48px/64px Segoe UI; */
  letter-spacing: 0px;
  color: #003a40;
  opacity: 0.8;
}
h3 {
  font-size: 13px;
}

.home-link {
  width: 100%;
  text-align: center;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.home-link button {
  color: white;
  font-size: 13px !important;
  font-weight: 700;
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: 60%;
}

body {
  height: 100vh;
}
.pac-container {
  z-index: 1100 !important;
}
.pac-item {
  text-align: center;
}

.cu-pos-btn {
  background-color: gray;
  color: white;
}

/* .Contenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  animation-duration: 1s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    width: 300%;
  }

  to {
    width: 100%;
  }
} */
